<style lang="scss" scoped>
@import "../styles/section";
.drink {
  .section-header {
    background-image: url("https://ik.imagekit.io/kreatikart/patrol_paris/interior-bar-2-min.jpg");
    background-position: center bottom;
  }
  .section-content {
    .container {
      background-image: url("../assets/pattern-drink.svg");
    }
    @media screen and (min-device-width: 900px) {
      bottom: 40px;
    }
  }
}
</style>

<template>
  <main class="section drink">
    <div class="section-header"></div>
    <transition
      @before-enter="staggerBeforeEnter"
      @enter="staggerEnter"
      @leave="staggerLeave"
    >
      <div
        class="section-content stick"
        v-show="$store.getters['swiper/getIndex'] === 3"
      >
        <div class="container">
          <h1 class="section-title" data-stagger>patrol<span>drinks</span></h1>
          <p class="section-text" data-stagger>
            Le bartender du Patrol propose ses cocktails
            <span class="handwriting">Signature </span> et sa selection
            originale de vins, de bières...
          </p>
          <div class="section-actions" data-stagger>
            <router-link
              class="base-btn primary"
              :to="{ name: 'SignatureCocktails' }"
              >Signature cocktails</router-link
            >
            <router-link class="base-btn primary" :to="{ name: 'DrinksWines' }"
              >Drinks & Wines</router-link
            >
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import { stagger } from "@/js/gsap-transitions-hooks";

export default {
  name: "Food",
  computed: {
    getSwiperProgress() {
      return this.$store.getters["getSwiperProgress"];
    }
  },
  methods: {
    ...stagger
  }
};
</script>
