<style lang="scss" scoped>
@import "../styles/section";
.about {
  .section-header {
    background-image: url("https://ik.imagekit.io/kreatikart/patrol_paris/exterior-marque-2-min.jpg");
    background-position: center 60%;
  }
  .section-content {
    .container {
      background-image: url("../assets/pattern-about.svg");
    }
    @media screen and (min-device-width: 900px) {
      bottom: 0px;
    }
  }
}
</style>

<template>
  <section class="section about">
    <div class="section-header"></div>
    <transition
      @before-enter="staggerBeforeEnter"
      @enter="staggerEnter"
      @leave="staggerLeave"
    >
      <div
        class="section-content"
        v-show="$store.getters['swiper/getIndex'] === 1"
      >
        <div class="container">
          <h1 class="section-title" data-stagger>patrol</h1>
          <h2 class="section-caption hr" data-stagger>the weekly brunch</h2>
          <p class="section-text" data-stagger>
            Véritable lieu de vie décontracté, où l’on se retrouve entre amis
            pour petit déjeuner, bruncher, diner et prendre un verre ou chiller
            en terrasse.
          </p>
          <div class="section-actions" data-stagger>
            <router-link class="base-btn primary" :to="{ name: 'Booking' }"
              >Réserver en ligne</router-link
            >
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { stagger } from "@/js/gsap-transitions-hooks";

export default {
  name: "About",
  methods: {
    ...stagger
  }
};
</script>
